import React from 'react';
import {NavLink} from 'react-router-dom';
import { translate } from "../../../components/LanguageTranslation/translate";

export default function Navs() {
  let pid = JSON.parse(localStorage.getItem("RIFALYPUBLISHER")).companyId;

  return (
    <ul className="nav nav-tabs">

        <li className="nav-item">
          <NavLink to="/reports/revenue" className="nav-link">{translate("Revenue","Mapato")}</NavLink>
        </li>
        {[6].includes(parseInt(pid)) ? (
        <li className="nav-item">
          <NavLink to="/reports/corporate-subscriptions" className="nav-link">{translate("Corporate Subscriptions","Vifurushi vya Makampuni")}</NavLink>
        </li> ) : null}
        <li className="nav-item">
          <NavLink to="/reports/corporate-downloads" className="nav-link">{translate("Corporate Downloads","Upakuaji wa Makampuni")}</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/reports/directdownloads" className="nav-link">{translate("Direct Downloads","Upakuaji wa moja kwa moja")}</NavLink>
        </li>
        <li className="nav-item">
          <NavLink to="/reports/subscriptiondownloads" className="nav-link">{translate("Subscription Downloads","Upakuaji kwa vifurushi")}</NavLink>
        </li>
    </ul>
  )
}

